<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="success"
          class="v-avatar-light-bg success--text"
          v-on="on"
        >
          <!-- <v-img :src="require('@/assets/images/avatars/1.png')"></v-img> -->
          {{ initials }}
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg success--text"
          >
            <!-- <v-img :src="require('@/assets/images/avatars/1.png')"></v-img> -->
            {{ initials }}
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align: middle"
        >
          <span class="text--primary font-weight-semibold mb-n1 pr-4">
            {{ this.name }}
          </span>
          <!-- <small class="text--disabled text-capitalize">{{$t('Admin')}}</small> -->
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <!-- Profile -->
      <v-list-item @click="handleNav('profile')">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("Profile") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- FAQ -->
      <!-- <v-list-item @click="handleNav('faq')">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("FAQ") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- Portal viejo -->
      <!-- <v-list-item @click="handleOuter()">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiPageNextOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Portal legacy</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="handleLogout()">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("Logout") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiPageNextOutline,
} from "@mdi/js";

import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiPageNextOutline,
      },
    };
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    async handleLogout() {
      try {
        await this.logout();
      } catch (error) {
        this.$dialog.notify.error(error.message);
      } finally {
        this.$router.push({ name: "auth-login" });
      }
    },
    handleNav(nav) {
      this.$router.push({ name: nav });
    },
    handleOuter() {
      window.open("https://admin.ridery.app/corporate_login", "_blank").focus();
    },
  },
  computed: {
    ...mapState("auth", ["name"]),
    initials() {
      if (this.name) {
        const fullName = this.name.split(" ");
        if (fullName.length === 2) {
          const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
          return initials.toUpperCase();
        } else {
          const initials = this.name[0];
          return initials.toUpperCase();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
