import {
  mdiHomeOutline,
  mdiHistory,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiPackageVariantClosed,
  mdiMapMarkerPath,
} from "@mdi/js";
import trip from "@/assets/Trip.svg";

export default [
  {
    title: "Dashboards",
    icon: mdiHomeOutline,
    to: "dashboards",
  },
  {
    subheader: "REQUESTS",
  },
  {
    title: "Running trips",
    icon: trip,
    to: "runningTrips",
  },
  {
    title: "Multistop",
    icon: mdiMapMarkerPath,
    to: "multiStop",
    module: "multiStop",
  },
  {
    title: "History",
    icon: mdiHistory,
    to: "history",
  },
  {
    subheader: "PARCELS",
  },
  {
    title: "Running parcels",
    icon: mdiPackageVariantClosed,
    to: "runningParcels",
    module: "parcel",
  },
  // {
  //   title: "Multiparadas ",
  //   icon: mdiMapMarkerPath,
  //   to: "parcelMultiStop",
  //   module: "parcel",
  // },
  {
    title: "History ",
    icon: mdiHistory,
    to: "parcelHistory",
    module: "parcel",
  },
  {
    subheader: "USERS",
  },
  {
    title: "Payroll",
    icon: mdiAccountOutline,
    to: "users",
  },
  {
    subheader: "FINANCIAL",
  },
  {
    title: "Balance",
    icon: mdiCurrencyUsd,
    to: "balance",
  },
];
