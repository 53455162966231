import "@/plugins/vue-composition-api";
import "@/styles/styles.scss";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { i18n } from "@/plugins/i18n";
import "@/plugins/vuetify-dialog";
import DatetimePicker from "vuetify-datetime-picker";
import "@/plugins/vee-validate";
import * as VueGoogleMaps from "vue2-google-maps";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import "./filters";
import money from "v-money";
import VueCurrencyFilter from "vue-currency-filter";
import VueExcelXlsx from "vue-excel-xlsx";
import VueSocketIO from "vue-socket.io";
import Carousel3d from "vue-carousel-3d";
import isSpanish from "@/plugins/isSpanish";
import { mapActions, mapState } from "vuex";

Vue.config.productionTip = false;
Vue.use(DatetimePicker);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_API_KEY,
    libraries: "places",
  },
});
Vue.use(VuetifyGoogleAutocomplete, {
  /*
    not used as loaded with component
    apiKey: key,
  */
  vueGoogleMapsCompatibility: true,
});

const moment = require("moment");
require("moment/locale/es");

Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(money, { precision: 2 });

Vue.use(
  VueCurrencyFilter,
  {
    symbol: "$",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
  },
  {
    name: "currency_2",
    symbol: "Bs.",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: false,
    avoidEmptyDecimals: undefined,
  }
);

Vue.use(VueExcelXlsx);

var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

Vue.use(
  new VueSocketIO({
    debug: false,
    credrentials: true,
    connection: process.env.VUE_APP_WEBSOCKET,
  })
);

Vue.use(Carousel3d);

Vue.use(isSpanish);

//Hotjar
import Hotjar from '@hotjar/browser';

const siteId = 5105727;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
