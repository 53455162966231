<template>
  <v-card tile class="">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>Viaje Cancelado</h2>
          </v-col>
          <v-col cols="12">
            <p class="text-lg">El viaje:</p>
            <p>
              <a
                :href="`/view-map/${data.trip_id}`"
                target="_blank"
                class="text-decoration-underline text-lg"
              >
                {{ data.trip_unique_id }}
              </a>
            </p>
            <p class="text-lg">ha sido cancelado por el conductor</p>
          </v-col>
        </v-row>
        <v-row
          class="
            d-flex
            flex-md-row flex-column-reverse
            justify-center
            align-center
          "
        >
          <v-col cols="12" md="4">
            <v-btn color="primary" block @click="handleClose()">
              Aceptar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import { mdiMagnify, mdiAlert } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  props: {
    data: {
      type: Object,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
      },
    };
  },
  methods: {
    ...mapActions(["setDriverCancelledEvent"]),

    handleClose() {
      this.setDriverCancelledEvent(!this.stateDriverCancelledEvent)
      this.$emit("submit", null);
    },
  },
  computed: {
    ...mapState(["stateDriverCancelledEvent"]),
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
    // color: white
    // opacity: 1
    color: rgba(58, 53, 65, 0.54)
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px
</style>
