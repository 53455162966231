import {
  mdiHomeOutline,
  mdiHistory,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiPackageVariantClosed,
  mdiMapMarkerPath,
} from "@mdi/js";
import trip from "@/assets/Trip.svg";

export default [
  {
    title: "Dashboards",
    icon: mdiHomeOutline,
    to: "dashboards",
  },
  {
    subheader: "USERS",
  },
  {
    title: "CorporatePanels",
    icon: mdiAccountOutline,
    to: "corporatePanels",
  },
  {
    subheader: "FINANCIAL",
  },
  {
    title: "Balance",
    icon: mdiCurrencyUsd,
    to: "balance",
  },
];
