import Vue from 'vue'
import vuetify from './vuetify'
import store from '../store'
import axios from '../plugins/axios'

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

const context = {
  store,
  vuetify,
  axios
}

Vue.use(VuetifyDialog, {
  context,
  notification: {
    position: 'top-right',
  },
})
