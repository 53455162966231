import axios from "@/plugins/axios";

export async function signIn({ commit }, user) {
  commit("SET_LOADING", true, { root: true });
  try {
    const { data } = await axios.post("/login", {
      email: user.email,
      password: user.password,
    });
    if (data) {
      commit("SET_USER", {
        user: data.email,
        _id: data._id,
        unique_id: data.unique_id,
      });
      commit("SET_TOKEN", data.token);
    }
  } catch (e) {
    throw e;
  } finally {
    commit("SET_LOADING", false, { root: true });
  }
}

export function setCurrentUser({ commit }) {
  commit("SET_USER", user);
}

export async function me({ commit }) {
  try {
    commit("SET_LOADING", true, { root: true });
    const { data } = await axios.get("/me");
    if (data && Boolean(data.corporate.is_approved)) {
      const { corporate } = data;
      commit("SET_ME", {
        email: corporate.email,
        name: corporate.name,
        countryName: corporate.country_name,
        phone: corporate.phone,
        countryPhoneCode: corporate.country_phone_code,
        wallet: corporate.wallet,
        overdraft: corporate.overdraft,
        picture_url: corporate.image_url ? corporate.image_url : null,
        pre_time: data.pre_time,
        admin_phone: data.admin_phone,
        admin_email: data.admin_email,
        is_active_shipments: corporate.is_active_shipments,
        is_active_multi_stops: corporate.is_active_multi_stops,
        is_corporate_master: corporate.is_corporate_master,
        is_used_google_maps_service: data.is_used_google_maps_service
      });
    } else {
      commit("LOGOUT", null);
      throw new Error("not approved");
    }
  } catch (e) {
    if (e.message === "not approved") {
      throw new Error(
        "Su usuario se encuentra no aprobado o suspendido. Para mayor información, comuníquese con un administrador de Ridery"
      );
    } else {
      throw e;
    }
  } finally {
    commit("SET_LOADING", false, { root: true });
  }
}

export async function meNotLoading({ commit }) {
  try {
    const { data } = await axios.get("/me");
    if (data && Boolean(data.corporate.is_approved)) {
      const { corporate } = data;
      commit("SET_ME", {
        email: corporate.email,
        name: corporate.name,
        countryName: corporate.country_name,
        phone: corporate.phone,
        countryPhoneCode: corporate.country_phone_code,
        wallet: corporate.wallet,
        overdraft: corporate.overdraft,
        picture_url: corporate.image_url ? corporate.image_url : null,
        pre_time: data.pre_time,
        admin_phone: data.admin_phone,
        admin_email: data.admin_email,
        is_active_shipments: corporate.is_active_shipments,
        is_active_multi_stops: corporate.is_active_multi_stops,
        is_corporate_master: corporate.is_corporate_master,
        is_used_google_maps_service: data.is_used_google_maps_service
      });
    } else {
      commit("LOGOUT", null);
      throw e;
    }
  } catch (e) {
    throw new Error(
      "Su usuario se encuentra no aprobado o suspendido. Para mayor información, comuníquese con un administrador de Ridery"
    );
  } finally {
  }
}

export async function logout({ commit }) {
  try {
    commit("SET_LOADING", true, { root: true });

    // await axios.post('/api/v1/logout')

    commit("LOGOUT", null);
  } catch (e) {
    throw "Error desconocido";
  } finally {
    commit("SET_LOADING", false, { root: true });
  }
}

export async function setLang({ commit }, lang) {
  commit("SET_LANG", lang);
}
