<template>
  <span class="mr-4" :class="!$vuetify.breakpoint.mdAndUp && 'text-xs'">
    <span :class="is_corporate_master && 'warning--text font-weight-bold'">
      PANEL ID: {{unique_id}} 
    </span>
  </span>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      
    }
  },
  computed: {
    ...mapState("auth", ["unique_id", "is_corporate_master"]),


  }
};
</script>
<style lang=""></style>
