// languagePlugin.js
export default {
  install(Vue, options) {
    // Use a mixin to add global properties or methods
    Vue.mixin({
      computed: {
        // Computed property to check language
        $isSpanish() {
          return this.$vuetify.lang.current === 'es';
        }
      }
    });
  }
};
