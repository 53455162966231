<template>
  <v-list-item
    v-if="canViewVerticalNavMenuLinkCustom(item)"
    v-bind="linkProps"
    :class="{ 'bg-gradient-primary white--text': isActive }"
    class="vertical-nav-menu-link"
    active-class="bg-gradient-primary"
  >
    <v-list-item-icon>
      <v-img v-if="item.title === 'Running trips'" :src="item.icon">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-icon
        v-else
        :class="{ 'alternate-icon-small': !item.icon }"
        :color="isActive ? 'white' : null"
      >
        {{ item.icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title :class="{ 'white--text': isActive }">
      {{ t(item.title) }}
      <span v-if="item.title === 'Balance' && wallet" class="ml-2 text-body-2">
        {{ wallet | currency }}</span
      >
    </v-list-item-title>
    <v-list-item-action v-if="item.badge" class="flex-shrink-0">
      <v-badge :color="item.badgeColor" inline :content="item.badge"> </v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import themeConfig from "@themeConfig";
import { useUtils } from "@core/libs/i18n";
import { useUtils as useAclUtils } from "@core/libs/acl";
import useVerticalNavLink from "@core/layouts/composable/vertical-nav/useVerticalNavLink";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps } = useVerticalNavLink(props.item);
    const { t } = useUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      alternateIcon: themeConfig.menu.groupChildIcon,

      // i18n
      t,

      // ACL
      canViewVerticalNavMenuLink,
    };
  },
  computed: {
    ...mapState("auth", ["wallet", "is_active_shipments", "is_active_multi_stops"]),
  },
  methods: {
    canViewVerticalNavMenuLinkCustom(item) {
      if (item.module === "parcel") {
        if (this.is_active_shipments) {
          return true;
        }
        return false;
      }
      if (item.module === 'multiStop') {
        if (this.is_active_multi_stops) {
          return true;
        }
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.vertical-nav-menu-link {
  font-family: "Urbanist", sans-serif !important;
  &.v-list-item--active {
    box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
    font-weight: bold !important;
    @include elevationTransition();
  }
}
.vertical-nav-menu-link:hover {
  font-weight: bold !important;
  background-color: rgba(94, 86, 105, 0.22);
}
</style>
