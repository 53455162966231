import { localize } from "vee-validate";

export function setVeeLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then((locale) => {
    localize(code, locale);
  });
}

export function truncator(string, length, end = "...") {
  return string
    ? string.length < length
      ? string
      : string.substring(0, length) + end
    : "";
}
export function capitalizeFirstLetter(str) {
  return str ? str[0].toUpperCase() + str.slice(1) : "";
}
export function toLowerCase(string) {
  return string.toLowerCase();
}
